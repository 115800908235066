<script>
  import Prodhost from "../components/host.js";
  import { onMount } from "svelte";
  import anime from "animejs";
  import BlobSlider from "../components/BlobSlider.svelte";
  import Mwelle from "../components/svg/mwelle.svelte";
  import isMobile from "../components/isMobile.js";

  function isFirefox() {
    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
      return true;
    }
    return false;
  }

  function isIe() {
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  }

  function isSafari() {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      return true;
    }
    return false;
  }

  var impressum = [];
  var ww = window.innerWidth;
  var wh = window.innerHeight;

  onMount(() => {
    fetch(Prodhost + "/colletadmin/api/singletons/get/teilnahmebedingungen")
      .then(response => response.json())
      .then(json => {
        impressum = json;
      });

    setTimeout(() => {
      if (ww >= 1024) {
        anime({
          targets: "#sliderblob .bblob",
          duration: 20000,
          easing: "linear",
          d: [
            {
              value:
                "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
            },
            {
              value:
                "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
            }
          ],
          loop: true,
          update: () => {
            if (
              navigator.userAgent.search("Safari") >= 0 &&
              navigator.userAgent.search("Chrome") < 0
            ) {
              const fimp = document.getElementById("msvg");
              fimp.style.display = "none";
              fimp.offsetWidth;
              fimp.style.display = "block";
            }
          }
        });
      }
    }, 300);

    const el = document.querySelector(".contentmain");
    if (ww > 960) {
      window.addEventListener("wheel", function(e) {
        let sp = e.deltaY + e.deltaX;
        if (e.deltaY >= 100) {
          sp = sp / 2;
        }
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          if (e.deltaY < 5) {
            sp = sp * 20;
          } else {
            sp = sp * 12;
          }
        }

        if (
          navigator.userAgent.search("Safari") >= 0 &&
          navigator.userAgent.search("Chrome") < 0 &&
          navigator.platform.indexOf("Mac") > -1
        ) {
          if (sp < 0) {
            sp = -80;
          } else {
            sp = 80;
          }
        }

        if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
        else el.scrollLeft -= sp * -1;
      });

      el.onscroll = function() {
        var elmnt = document.querySelector(".contentmain");
        var x = elmnt.scrollLeft;
      };
    }
  });
</script>

<style>#msvg{width:100%;height:100%;display:block}.svgclippath{position:absolute;top:-1000000px}.contenttext{column-count:5;text-align:left;font-size:12px;width:2700px;margin-left:450px}@media (max-width:1510px) and (min-width:961px){#blslider{left:20vw}}@media (max-width:961px){.contenttext{column-width:100%;width:100%}}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy90ZWlsbmFobWViZWRpbmd1bmdlbi5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0UsTUFDRSxVQUFXLENBQ1gsV0FBWSxDQUNaLGFBQ0YsQ0FDQSxhQUNFLGlCQUFrQixDQUNsQixjQUNGLENBRUEsYUFDRSxjQUFlLENBQ2YsZUFBZ0IsQ0FDaEIsY0FBZSxDQUVmLFlBQWEsQ0FFYixpQkFDRixDQVNBLGdEQUNFLFVBQ0UsU0FDRixDQUNGLENBRUEseUJBQ0UsYUFDRSxpQkFBa0IsQ0FDbEIsVUFDRixDQUNGIiwiZmlsZSI6InNyYy9wYWdlcy90ZWlsbmFobWViZWRpbmd1bmdlbi5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcclxuICAjbXN2ZyB7XHJcbiAgICB3aWR0aDogMTAwJTtcclxuICAgIGhlaWdodDogMTAwJTtcclxuICAgIGRpc3BsYXk6IGJsb2NrO1xyXG4gIH1cclxuICAuc3ZnY2xpcHBhdGgge1xyXG4gICAgcG9zaXRpb246IGFic29sdXRlO1xyXG4gICAgdG9wOiAtMTAwMDAwMHB4O1xyXG4gIH1cclxuXHJcbiAgLmNvbnRlbnR0ZXh0IHtcclxuICAgIGNvbHVtbi1jb3VudDogNTtcclxuICAgIHRleHQtYWxpZ246IGxlZnQ7XHJcbiAgICBmb250LXNpemU6IDEycHg7XHJcbiAgICAvKiBtaW4td2lkdGg6IDUwMDBweDsgKi9cclxuICAgIHdpZHRoOiAyNzAwcHg7XHJcblxyXG4gICAgbWFyZ2luLWxlZnQ6IDQ1MHB4O1xyXG4gIH1cclxuXHJcbiAgLyogQG1lZGlhIChtYXgtd2lkdGg6IDk2MHB4KSB7XHJcbiAgICAuc2xpZGVyVGV4dEJveCB7XHJcbiAgICAgIHRvcDogNzBweDtcclxuICAgICAgbGVmdDogMTAwcHg7XHJcbiAgICB9XHJcbiAgfSAqL1xyXG5cclxuICBAbWVkaWEgKG1heC13aWR0aDogMTUxMHB4KSBhbmQgKG1pbi13aWR0aDogOTYxcHgpIHtcclxuICAgICNibHNsaWRlciB7XHJcbiAgICAgIGxlZnQ6IDIwdnc7XHJcbiAgICB9XHJcbiAgfVxyXG5cclxuICBAbWVkaWEgKG1heC13aWR0aDogOTYxcHgpIHtcclxuICAgIC5jb250ZW50dGV4dCB7XHJcbiAgICAgIGNvbHVtbi13aWR0aDogMTAwJTtcclxuICAgICAgd2lkdGg6IDEwMCU7XHJcbiAgICB9XHJcbiAgfVxyXG4iXX0= */</style>

{#if isIe()}
  <style>#blslider{left:30vw}.contentmain>section>.columns{height:100vh;width:max-content}.contenttext{column-width:300px;height:80%;width:100%}.contentmain>section .contenttextblock{width:100%;display:block;margin-top:20vh;float:left}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy90ZWlsbmFobWViZWRpbmd1bmdlbi5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0ksVUFDRSxTQUNGLENBQ0EsOEJBQ0UsWUFBYSxDQUNiLGlCQUNGLENBRUEsYUFDRSxrQkFBbUIsQ0FDbkIsVUFBVyxDQUNYLFVBQ0YsQ0FFQSx1Q0FDRSxVQUFXLENBQ1gsYUFBYyxDQUNkLGVBQWdCLENBQ2hCLFVBQ0YiLCJmaWxlIjoic3JjL3BhZ2VzL3RlaWxuYWhtZWJlZGluZ3VuZ2VuLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxyXG4gICAgI2Jsc2xpZGVyIHtcclxuICAgICAgbGVmdDogMzB2dztcclxuICAgIH1cclxuICAgIC5jb250ZW50bWFpbiA+IHNlY3Rpb24gPiAuY29sdW1ucyB7XHJcbiAgICAgIGhlaWdodDogMTAwdmg7XHJcbiAgICAgIHdpZHRoOiBtYXgtY29udGVudDtcclxuICAgIH1cclxuXHJcbiAgICAuY29udGVudHRleHQge1xyXG4gICAgICBjb2x1bW4td2lkdGg6IDMwMHB4O1xyXG4gICAgICBoZWlnaHQ6IDgwJTtcclxuICAgICAgd2lkdGg6IDEwMCU7XHJcbiAgICB9XHJcblxyXG4gICAgLmNvbnRlbnRtYWluID4gc2VjdGlvbiAuY29udGVudHRleHRibG9jayB7XHJcbiAgICAgIHdpZHRoOiAxMDAlO1xyXG4gICAgICBkaXNwbGF5OiBibG9jaztcclxuICAgICAgbWFyZ2luLXRvcDogMjB2aDtcclxuICAgICAgZmxvYXQ6IGxlZnQ7XHJcbiAgICB9XHJcbiAgIl19 */</style>
{/if}

{#if isFirefox()}
  <style>.contentmain>section>.columns{height:100vh;width:max-content}.contenttext{column-width:300px}#blslider{left:30vw}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy90ZWlsbmFobWViZWRpbmd1bmdlbi5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0ksOEJBQ0UsWUFBYSxDQUNiLGlCQUNGLENBRUEsYUFDRSxrQkFDRixDQUVBLFVBQ0UsU0FDRiIsImZpbGUiOiJzcmMvcGFnZXMvdGVpbG5haG1lYmVkaW5ndW5nZW4uc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXHJcbiAgICAuY29udGVudG1haW4gPiBzZWN0aW9uID4gLmNvbHVtbnMge1xyXG4gICAgICBoZWlnaHQ6IDEwMHZoO1xyXG4gICAgICB3aWR0aDogbWF4LWNvbnRlbnQ7XHJcbiAgICB9XHJcblxyXG4gICAgLmNvbnRlbnR0ZXh0IHtcclxuICAgICAgY29sdW1uLXdpZHRoOiAzMDBweDtcclxuICAgIH1cclxuXHJcbiAgICAjYmxzbGlkZXIge1xyXG4gICAgICBsZWZ0OiAzMHZ3O1xyXG4gICAgfVxyXG4gICJdfQ== */</style>
{/if}

<section class="section mainslider ins">
  <div class="mwelletop">
    <Mwelle />
  </div>
  <div>
    {#if impressum.bild}
      {#if isIe()}
        <svg id="mainbild">
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            xlink:href="{Prodhost}/colletadmin/{impressum.bild.styles[0].path}"
            clip-path="url(#sliderblob)" />
        </svg>
      {:else}
        <div id="mainbild">
          {#if ww >= 1024}
            <style>#msvg{clip-path:url(#sliderblob);-webkit-clip-path:url(#sliderblob)}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy90ZWlsbmFobWViZWRpbmd1bmdlbi5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ2MsTUFDRSwwQkFBNkIsQ0FDN0Isa0NBQ0YiLCJmaWxlIjoic3JjL3BhZ2VzL3RlaWxuYWhtZWJlZGluZ3VuZ2VuLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxyXG4gICAgICAgICAgICAgICNtc3ZnIHtcclxuICAgICAgICAgICAgICAgIGNsaXAtcGF0aDogdXJsKFwiI3NsaWRlcmJsb2JcIik7XHJcbiAgICAgICAgICAgICAgICAtd2Via2l0LWNsaXAtcGF0aDogdXJsKFwiI3NsaWRlcmJsb2JcIik7XHJcbiAgICAgICAgICAgICAgfVxyXG4gICAgICAgICAgICAiXX0= */</style>
          {/if}
          <div
            id="msvg"
            style="background-image: url({Prodhost}/colletadmin/{impressum.bild.styles[0].path});" />
        </div>
      {/if}
    {/if}
  </div>
  <div id="blslider">
    <BlobSlider />
    <div class="sliderTextBox is-vertical-center">
      <h1>
        {@html impressum.title}
      </h1>
    </div>
  </div>

  <Mwelle />
</section>

<section class="section">

  <div class="columns is-vcentered contenttextblock">
    <div
      class="column contenttext"
      style={ww > 960 ? '    column-count: 7!important;' : ''}>
      {@html impressum.text}
    </div>
  </div>
</section>

<div class="svgclippath">
  <svg viewBox="0 0 1200 900" width="0" height="0">
    <clipPath
      id="sliderblob"
      clipPathUnits="objectBoundingBox"
      transform="scale(0.0008, 0.002)">
      <path
        style=" transform: translate(-180px, 0px);"
        transform="translate(-180, 0)"
        class="bblob"
        d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
        143.61c-4.55,95.55,170.07,212.41,10.95,462.77
        C1005.43,799.35,1195.72,900.48,1200,900z" />
    </clipPath>
  </svg>
</div>
